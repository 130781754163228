/** @define video-section **/
.video-section {
  position: relative;
  height: 100%;
  @include media-breakpoint-down(lg) {
    z-index: 1;
  }
  @include media-breakpoint-down(sm) {
    max-height: 500px;
  }
}

/** @define visual-caption **/
.visual-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  &__des {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-bottom: 117px;
    @include media-breakpoint-down(sm) {
      display: block;
      align-items: end;
      padding-bottom: 50px;
    }
  }

  /* postcss-bem-linter: ignore */
  .container {
    position: relative;
  }
  /* postcss-bem-linter: ignore */
  h1 {
    font-weight: 600;
    color: $white;
    margin: 0 0 16px;
    text-align: right;
    font-style: italic;
    line-height: 70px;
    letter-spacing: -0.5px;
    @include media-breakpoint-down(md) {
      line-height: 36px;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}

/** @define count-box **/
.count-box {
  @include font-size( 24px , 26px);
  position: absolute;
  left: 15px;
  bottom: -26px;
  width: 195px;
  background: $elephant;
  text-align: center;
  color: $white;
  padding: 11px 0 19px;
  font-weight: 600;
  @include media-breakpoint-down(sm) {
    @include font-size( 32px , 35px);
    display: block;
    margin-bottom: -76px;
    position: static;
    width: 100%;
    left: 0;
  }


  &__number {
    @include font-size( 86px , 86px);
    color: $ripe-lemon;
    font-weight: 600;
    display: block;
    @include media-breakpoint-down(sm) {
      @include font-size( 42px , 46px);
      padding-right: 10px;
      display: inline-block;
    }
  }
}

/* stylelint-disable */
.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -70px;
  width: 132px;
  height: 75px;
  background: $picton-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  @include media-breakpoint-down(lg) {
    width: 70px;
    height: 40px;
    margin-left: -35px;
  }

  &:hover {
    background: $globalblue;
  }

  i {
    display: inline-block;
    vertical-align: top;
    background: url(../img/arrow-down.png) no-repeat;
    width: 44px;
    height: 28px;
    font-size: 0;
    line-height: 0;
    background-size: 100% 100%;
  }
}
#soliloquy-container-174 {
  width: 100%;
  margin: 0 auto;
}
.soliloquy-container .soliloquy-item,
.soliloquy-container .soliloquy-image {
  height: 100% !important;
}
body.home .soliloquy-pager,
body.home .soliloquy-controls-direction {
  display: none;
}
#soliloquy-container-174 {
  max-height: 100% !important;
}
.logo_slogon a {
  display: block;
}
.logo_slogon br {
  display: none;
}

.soliloquy-container .soliloquy-item, .soliloquy-container .soliloquy-image {
  @include media-breakpoint-down(md) {
    height: auto !important;
  }
}