/** @define about-area **/
.about-area {
  padding: 48px 0 85px;
  @include media-breakpoint-down(md) {
    padding-bottom: 40px;
  }
}

/** @define about-info **/
.about-info {
  @include font-size( 24px , 30px);
  padding: 44px 0 0;
  font-family: $chaparral-pro;
  @include media-breakpoint-down(lg) {
    @include font-size(19px , 28px);
  }
  @include media-breakpoint-down(md) {
    @include font-size(16px , 20px);
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  &__heading {
    margin: 0 0 28px;
    color: $globalblue;
    font-family: $urbana;
  }
}

/** @define news-block **/
.news-block {
  background: $fruit-salad;
  padding: 43px 50px;
  color: $white;
  position: relative;
  margin: 0 -52px 0 35px;
  @media (max-width: 1500px) {
    margin: 0;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 15px;
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  &__head {
    margin: 0 0 10px;
    font-family: $urbana;
  }

  &__list {
    @include listreset;
    @include font-size( 28px , 32px);
    font-weight: 300;
    @include media-breakpoint-down(lg) {
      @include font-size( 18px , 22px);
    }


    /* stylelint-disable */
    li {
      position: relative;
      padding: 15px 0;

      &::after {
        position: absolute;
        left: 50%;
        height: 3px;
        bottom: 0;
        background: rgba($elephant , 0.20);
        content: '';
        width: 150px;
        margin-left: -75px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    a {
      color: $white;

      &:hover {
        color: #f7e41e;
      }
    }
  }
  .btn-primary {
    position: absolute;
    bottom: -33px;
    right: 58px;
    height: 70px;
    line-height: 70px;
    font-weight: 300;
    @include media-breakpoint-down(lg) {
      height: 40px;
      line-height: 40px;
      bottom: -20px;
      left: 0;
    }
    @include media-breakpoint-down(sm) {
      right: 0;
      left: 0;
      margin: 0 auto;
      max-width: 100%;
    }

  }
}

/** @define categories **/
.categories {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 92px;
  @include media-breakpoint-down(lg) {
    padding: 0 0 50px;
  }

  &__box {
    position: relative;
    width: 32.699%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover {
      &::before {
        opacity: 0.5;
        visibility: visible;
      }
      .btn-primary {
        opacity: 1;
        visibility: visible;
      }
    }

    &.style01 {
      width: 67.301%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .text-box {
        align-items: flex-start;
        justify-content: flex-end;
        @include media-breakpoint-down(sm) {
          justify-content: center;
          align-items: center;
        }
      }
    }

    &.style02 {
      width: 34.777%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .text-box {
        align-items: flex-start;
        @include media-breakpoint-down(sm) {
          justify-content: center;
          align-items: center;
        }
      }
    }

    &.style03 {
      width: 32.495%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .text-box {
        align-items: flex-end;
        justify-content: flex-end;
        @include media-breakpoint-down(sm) {
          justify-content: center;
          align-items: center;
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      @include media-breakpoint-down(sm) {
        min-height: 260px;
        object-fit: cover;
      }
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #70aa3e;
      opacity: 0;
      box-shadow: 0px 3px 35px $black inset;
      visibility: hidden;
    }
  }

  .text-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 34px;
    color: #fff;
    line-height: 36px;
    padding: 27px 32px 29px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-down(lg) {
      padding: 27px 20px 29px;
      font-size: 32px;
    }
    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 24px;
    }
    @include media-breakpoint-down(sm) {
      justify-content: center;
      align-items: center;
    }

    span {
      display: block;
      font-size: 24px;
      line-height: 27px;
      font-family: $chaparral-pro;
      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .btn-primary {
    position: absolute;
    bottom: -26px;
    right: 44px;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    @include media-breakpoint-down(md) {
      right: 0;
    }
  }

}

.visual {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.content-area {
  padding: 80px 0;
  font-family: $chaparral-pro;
  font-size: 24px;
  line-height: 32px;
  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 22px;
    padding: 40px 0;
  }

  h1 {
    font-size: 45px;
    line-height: 48px;
    color: #8c181b;
    margin-bottom: 24px;
    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 15px;
    }
  }

  h3 {
    font-size: 35px;
    line-height: 37px;
    color: #347232;
    margin:0;
    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  p {
    margin: 0 0 40px;
    color: #333;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  strong {
    font-weight: 700;
    color: #333;
    font-size: 21px;
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  ul {
    font-size: 21px;
    color: #333;
    margin-bottom: 30px;
    padding-left: 20px;
    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .alignleft {
    float: left;
    margin: 0 60px 50px 0;
    @include media-breakpoint-down(md) {
      width: 50%;
      height: auto;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0 0 20px;
      float: none;
    }
  }

  .alignright {
    float: right;
    margin: 0 0 50px 60px;
    @include media-breakpoint-down(md) {
      width: 50%;
      height: auto;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0 0 20px;
      float: none;
    }
  }
}

.logo_slogon a {
  color: #fff;
  &:hover {
    color: hsl(55, 93%, 54%);
  }
}

#gform_submit_button_2 {
  cursor: pointer;
}

