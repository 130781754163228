/* stylelint-disable */

@include media-breakpoint-down(md) {
  #menu-header-menu {
    margin:0;
    padding: 0;

    ul {
      display: none;
      position: static !important;
      width: 100% !important;
      float: none;
      padding: 0;
    }
  }
}
@include media-breakpoint-down(md) {
  .nav .nav-area a.dropdown-toggle {
    background: #fff !important;
  }
}
.dropdown ul {
  background: #144355;
}
.dropdown-menu {
  transform: translate3d(0px, 0px, 0px) !important;
}
@include media-breakpoint-up(md) {
  .dropdown ul {
    top: 32px !important;
  }
}

