// custom variables and override bootstrap variables
$gray: #656565;
$white: #fff;
$black: #000;
$gray2: #f6f6f6;
$gray3: #363636;
$base-min-width: 320px;
// This is the default html and body font-size for the base rem value.
$theme-colors: (
  primary: #f1764b,
  secondary: #333,
);
$grid-breakpoints: (
  xs: 0, 
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1176px
);
$elephant: #347232;
$globalblue: #144355;
$ripe-lemon: #f7e41e;
$picton-blue: #8c181b;
$goblin: #347232;
$fruit-salad: #347232;

$arial-font: 'Arial', 'Helvetica', sans-serif;
$chaparral-pro: 'chaparral-pro',serif;
$urbana: 'urbana',sans-serif;
$body-color: $globalblue;
$link-color: $picton-blue;
$link-hover-decoration: none;
$font-family-base: $urbana;
$btn-transition: all 0.4s ease;
$headings-font-family: $urbana;
$headings-font-weight: 600;
$h1-font-size: 70px;
$h2-font-size: 34px;
$h3-font-size: 20px;
$line-height-base: 2.1;

