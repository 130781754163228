/** @define top-section **/
.top-section {
  position: relative;
  @include media-breakpoint-down(sm) {
    max-height: 460px;
  }
}

/** @define header **/
.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  @include media-breakpoint-down(md) {
    background: $white;
    position: static;
    min-height: 20px;
  }

  &__holder {
    position: relative;
    padding-left: 178px;
    @include media-breakpoint-down(md) {
      padding: 15px 0;
    }
  }
}

/** @define logo **/
.logo {
  width: 148px;
  position: absolute;
  left: 17px;
  top: 33px;
  z-index: 99;
  @include media-breakpoint-down(md) {
    position: static;
    width: 90px;
    margin-left: 5%;
  }
  @include media-breakpoint-down(sm) {
    width: 70px;
  }
}

/** @define video-section **/
.video-section {
  position: relative;
  background-size: cover;
}
/* stylelint-disable */
@include media-breakpoint-down(lg) {
  .header .container {
    max-width: 100%;
  }
}
.page-template-default .soliloquy-container {
  display: table;
  margin: 0 auto 20px;
  max-width: 100%;
  table-layout: fixed;
}
